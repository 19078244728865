/** @jsx jsx **/
import { PureAccordionItem } from './pureAccorionItem'
import { Box, jsx } from 'theme-ui'
import { FC, ReactNode, useState } from 'react'

import MarkdownSerializer from 'markdown-it'

type PropsItem = {
  label: string
  content: ReactNode | string
  identifier: string
}
type Props = {
  data: PropsItem[]
  activeItem?: string
  changeCallback?: (identifier: string) => void
}

const md = new MarkdownSerializer({
  html: true,
  linkify: true,
  typographer: true,
})

export const PureAccordion: FC<Props> = ({ data, activeItem, changeCallback }) => {
  const [active, setActive] = useState<string>(activeItem || data[0].identifier)

  const handleChange = (identifier: string) => {
    setActive(identifier)
    if (changeCallback) {
      changeCallback(identifier)
    }
  }

  return (
    <Box>
      {data.map((item, index) => (
        <PureAccordionItem
          key={item.identifier}
          title={item.label}
          currentActive={active}
          identifier={item.identifier}
          setCurrentActive={handleChange}
        >
          <div className="content">
            <Box
              sx={{
                p: {
                  lineHeight: 1.25,
                },
                li: {
                  mb: 4,
                  lineHeight: 1.5,
                },
                a: {
                  color: 'primary',
                  fontWeight: 'bold',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: md.render(item.content),
              }}
            />
          </div>
        </PureAccordionItem>
      ))}
    </Box>
  )
}
