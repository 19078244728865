/** @jsx jsx **/
import { jsx, Box } from 'theme-ui'
import { FC } from 'react'
import { FONT_FAMILY } from '~/common/constants'
import { generateFaqSlug } from './helpers'
import { Themed } from '@theme-ui/mdx'
import { PureAccordion } from '../accordion/pureAccordion'
import { FaqItem } from './types'

type Props = {
  title: string
  index: number
  items: FaqItem[] | null
  initialIndex?: number
  activeItem?: string
}

const FaqCategory: FC<Props> = ({ title, items, activeItem }) => {
  if (!items) return null

  const setPageURI = (title: string) => {
    const href = `#${title}`

    if (typeof document !== 'undefined') {
      document.location.hash = href
      document?.getElementById(href)?.scrollIntoView()
    }
  }

  return (
    <Box
      style={{
        margin: '1rem 0 0',
      }}
    >
      <a
        id={generateFaqSlug(title)}
        style={{
          display: 'block',
          position: 'relative',
          top: '-100px', // header height
          visibility: 'hidden',
        }}
      />
      <Themed.h4
        as="h2"
        sx={{
          // pt: [space.l, null, space.xl2],
          fontSize: '16px !important',
          textTransform: 'uppercase',
          fontWeight: 500,
          fontFamily: FONT_FAMILY,
        }}
      >
        {title}
      </Themed.h4>
      <Box>
        <PureAccordion data={items} activeItem={activeItem} changeCallback={setPageURI} />
      </Box>
    </Box>
  )
}

export default FaqCategory
