/** @jsx jsx **/
import { jsx, Box, Grid, Container, Heading, Text } from 'theme-ui'
import { FC, ReactNode, useEffect, useState } from 'react'
import FaqCategory from '~/components/faq-category'
import Layout from '~/components/layout'
import FaqNavItem from '~/components/faq-nav-item'
import { graphql, useStaticQuery } from 'gatsby'
import { space } from '~/gatsby-plugin-theme-ui'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { FONT_FAMILY } from '~/common/constants'
import { useLocation } from 'react-use'
import { navigate } from '@reach/router'
import { generateFaqSlug, mountFaq } from '~/components/faq-category/helpers'
import { Themed } from '@theme-ui/mdx'
import HeadingV2 from '~/componentsV2/heading'

type Props = {
  title: string
  description: string | ReactNode
}

type QueryResult = {
  pageData: {
    nodes: Array<{
      title: string
      faq: Array<{
        answer: string
        question: string
      }>
    }>
  }
}

const Faqs: FC<Props> = ({ title, description }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [active, setActive] = useState<string | null>(null)

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: allFaqJson(sort: { order: ASC, fields: order }) {
        nodes {
          title
          faq {
            answer
            question
          }
        }
      }
    }
  `)

  const faqCategories = query.pageData.nodes.map(({ title, faq }) => ({
    title,
    items: mountFaq({ items: faq }),
  }))

  const { xs } = useBreakpoint()
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      const activeIndexFromLocation = parseInt(hash.split('-')[1])
      if (isNaN(activeIndexFromLocation)) {
        return
      }
      setActiveIndex(activeIndexFromLocation)
    }
  }, [hash, setActiveIndex])

  useEffect(() => {
    if (hash && faqCategories) {
      faqCategories.forEach(faqCategorie => {
        const found = faqCategorie?.items?.find(
          ({ identifier }) => identifier === hash.replace('#', '')
        )

        if (found) {
          setActive(found.identifier)

          if (typeof document !== 'undefined') {
            const offSet = -50
            const element = document?.getElementById(`${found.identifier}`)

            if (!element) {
              return
            }
            const positonY = element.getBoundingClientRect().top + window.scrollY + offSet
            window.scrollTo({ top: positonY, behavior: 'smooth' })
          }
        }
      })
    }
  }, [hash])

  const isMobile = xs === false

  return (
    <Layout headerVariant="default" title={title}>
      <Container
        sx={{
          my: [space.sm1, null],
        }}
      >
        <Themed.div
          sx={{
            width: '100%',
            flex: 1,
            maxWidth: 1280,
            mt: 50,
          }}
        >
          <Box as="header">
            <HeadingV2
              sx={{
                fontSize: '32px!important',
              }}
            >
              {title}
            </HeadingV2>
            <Container
              variant="containerSecondary"
              sx={{
                p: 0,
              }}
            >
              <Text
                variant="regular"
                sx={{
                  color: 'muted',
                }}
              >
                {description}
              </Text>
            </Container>
          </Box>
        </Themed.div>
        <Grid
          columns={['1fr', '3fr 1fr', '3fr 1fr', '3fr 1fr']}
          sx={{
            position: 'relative',
            mt: 50,
            mr: 'auto',
            ml: [null, null, null, 50],
          }}
        >
          <Box sx={isMobile ? { order: 2 } : {}} as="main">
            {faqCategories?.map((faqCategory, index) => (
              <FaqCategory
                key={faqCategory.title}
                title={faqCategory.title}
                items={faqCategory.items}
                index={index}
                initialIndex={activeIndex}
                activeItem={
                  faqCategory?.items?.find(({ identifier }) => identifier === active)
                    ? (active as string)
                    : undefined
                }
              />
            ))}
            {/* <FaqCategory
              key={faqCategories[0].title}
              title={faqCategories[0].title}
              items={faqCategories[0].faq}
              initialIndex={activeIndex}
              index={0}
            /> */}
          </Box>
          {!isMobile && (
            <Themed.div
              sx={{
                my: ['20px', null],
                width: isMobile ? '100%' : '330px',
                paddingLeft: isMobile ? '0px' : '50px',
                height: isMobile ? 'auto' : '500px', // hack
                fontFamily: FONT_FAMILY,
                boxSizing: 'border-box',
                position: 'sticky',
                top: 110,
              }}
            >
              <Themed.h3
                sx={{
                  width: '100%',
                  textTransform: 'uppercase',
                  fontFamily: FONT_FAMILY,
                  fontSize: '15px !important',
                  fontWeight: 500,
                }}
              >
                Quick Links
              </Themed.h3>
              {faqCategories?.map((faqCategory, index) => (
                <Themed.a
                  href={`#` + generateFaqSlug(faqCategory?.title)}
                  key={faqCategory.title}
                  sx={{
                    width: '100%',
                    textTransform: 'uppercase',
                    fontFamily: FONT_FAMILY,
                    fontSize: '14px',
                    textDecoration: 'none',
                    color: '#595858',
                    padding: '10px 0',
                    margin: '10px 0',
                    display: 'block',
                  }}
                >
                  {faqCategory.title}
                </Themed.a>
              ))}
            </Themed.div>
          )}
        </Grid>
      </Container>
    </Layout>
  )
}

export default Faqs
