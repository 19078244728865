import * as React from 'react'
import { PlusMenuExpand } from '../icons/plusMenuExpand'

import './pureAccordion.css'
import { Box } from 'theme-ui'

interface Props {
  identifier: string
  title: string
  children: React.ReactNode
  currentActive: string
  setCurrentActive: (identifier: string) => void
}

export const PureAccordionItem: React.FC<Props> = ({
  identifier,
  title,
  children,
  currentActive,
  setCurrentActive,
}) => {
  return (
    <div key={identifier} id={identifier} className="accordion">
      <Box
        className="accordion-title"
        sx={{
          padding: '20px 4px',
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          cursor: 'pointer',
          fontSize: '18px',
        }}
        onClick={() => {
          setCurrentActive(identifier)
        }}
      >
        {title}{' '}
        <PlusMenuExpand
          sx={{
            fill: '#666',
            transform: currentActive !== identifier ? 'rotate(180deg)' : 'rotate(0)',
            transition: '.3s all',
            minWidth: 10,
            ml: 16,
            polygon: {
              visibility: currentActive !== identifier ? 'visible' : 'hidden',
              opacity: currentActive !== identifier ? 1 : 0,
              transition: 'visibility 0s 0.5s, opacity 0.5s linear',
            },
          }}
        />
      </Box>
      <div className="accordion-content" aria-expanded={currentActive === identifier}>
        {children}
      </div>
    </div>
  )
}
